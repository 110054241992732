import React from "react";
import {
  Typography,
  Box,
  Button,
  Card,
  TextField,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import amplifyService from "../../services/amplifyService";

const Waitlist = () => {
  const theme = useTheme();
  const [email, setEmail] = React.useState("");
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [error, setError] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateInputs = () => {
    let valid = true;
    if (!validateEmail(email)) {
      setError("Email is not valid");
      valid = false;
    }
    return valid;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  async function submitWaitlist() {
    if (!validateInputs()) {
      return;
    }
    setError("");
    setButtonDisabled(true);
    setLoading(true);
    const response = await amplifyService.joinCopyTradingInterestGroup(email);
    if (response) {
      setSnackbarMessage("Submitted successfully!");
    } else {
      setSnackbarMessage("Unable to send. Please try again later.");
    }
    setSnackbarOpen(true);
    setLoading(false);
    setButtonDisabled(false);
  }

  return (
    <Box
      sx={{
        marginTop: "8rem",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        color={theme.palette.surface?.contrastText}
      >
        Join Our Waitlist
      </Typography>
      <Card
        elevation={0}
        sx={{
          backgroundColor: theme.palette.surfaceContainerLow?.main,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
          margin: "3rem 1.5rem",
          borderRadius: "16px",
        }}
      >
        <Box
          component="form"
          sx={{
            width: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            variant="filled"
            margin="normal"
            required
            color="secondary"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            sx={{
              marginTop: "6px",
              backgroundColor: theme.palette.tertiaryContainer?.main,
            }}
            value={email}
            onChange={(e) => {
              setError("");
              setEmail(e.target.value);
            }}
            error={!!error}
            helperText={error}
          />
          <Box>
            <Button
              disabled={buttonDisabled}
              type="button"
              variant="contained"
              color="primary"
              onClick={submitWaitlist}
              sx={{ mt: 1, mb: 0 }}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Card>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Box>
  );
};

export default Waitlist;
