import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { md3ThemeJson } from "../../Theme";

const TermsAndConditions = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: "4rem",
        paddingBottom: "4rem",
        backgroundColor: md3ThemeJson.schemes.dark.background,
        color: md3ThemeJson.schemes.dark.onBackground,
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom>
        Terms and Conditions
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Pioneer Investing Inc (&quot;Company,&quot; &quot;we,&quot;
          &quot;our,&quot; or &quot;us&quot;). By creating an account and using
          our services (&quot;Services&quot;), you agree to be bound by these
          Terms and Conditions (&quot;Terms&quot;). Please read them carefully.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          2. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing or using our Services, you agree to be bound by these
          Terms and our Privacy Policy. If you do not agree, you must not use
          our Services.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          3. Eligibility
        </Typography>
        <Typography variant="body1" paragraph>
          You must be at least 18 years old and capable of entering into a
          legally binding agreement to use our Services. By using the Services,
          you represent and warrant that you meet these requirements.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          4. Account Creation
        </Typography>
        <Typography variant="body1" paragraph>
          To access certain features, you must create an account. You agree to
          provide accurate and up-to-date information during registration and to
          keep this information updated. You are responsible for maintaining the
          confidentiality of your account credentials and are liable for all
          activities conducted under your account.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          5. Use of Services
        </Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by law, [Your Company Name] shall not
          be liable for any indirect, incidental, special, or consequential
          damages arising from or in connection with the use of our services.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          6. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          Our Services are for informational purposes only and do not constitute
          financial advice. We are not responsible for any decisions you make
          based on the information provided. Always consult with a financial
          advisor before making investment decisions.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          7. User Conduct
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to use the Services in compliance with all applicable laws
          and not to: (1) Misuse the Services or use them for fraudulent
          purposes. (2) Interfere with or disrupt the operation of the Services.
          (3) Post or transmit any harmful, offensive, or illegal content.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          8. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content, trademarks, and other intellectual property on our
          platform are owned by Pioneer Investing Inc or our licensors. You may
          not use, copy, or distribute this content without our prior written
          consent.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          9. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by law, Pioneer Investing Inc is not
          liable for any indirect, incidental, or consequential damages
          resulting from the use or inability to use our Services.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          10. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to suspend or terminate your account at our
          discretion for any reason, including violations of these Terms.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          11. Amendments
        </Typography>
        <Typography variant="body1" paragraph>
          We may modify these Terms at any time. Updates will be posted on our
          website, and your continued use of the Services constitutes acceptance
          of the revised Terms.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          12. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms are governed by the laws of the State of [Insert State],
          USA. Any disputes arising out of these Terms shall be resolved
          exclusively in the courts of [Insert State].
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          13. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at
          [support@pioneerinvesting.com].
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
