import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { md3ThemeJson } from "../../Theme";

const PrivacyPolicy = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: "4rem",
        paddingBottom: "4rem",
        backgroundColor: md3ThemeJson.schemes.dark.background,
        color: md3ThemeJson.schemes.dark.onBackground,
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom>
        Privacy Policy
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Pioneer Investing Inc (&quot;Company,&quot; &quot;we,&quot;
          &quot;our,&quot; or &quot;us&quot;) is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, and protect
          your personal information.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect the following types of information: - **Personal
          Information**: Name, email address, phone number, and payment details.
        </Typography>
        <Typography variant="body1" paragraph>
          Usage Data: Information about how you interact with our platform,
          including IP address, browser type, and device information.
        </Typography>
        <Typography variant="body1" paragraph>
          Cookies: Data stored on your device to enhance your user experience.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          3. Sharing of Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell your personal information. We may share your
          information with:
        </Typography>
        <Typography variant="body1" paragraph>
          - Service providers who assist in delivering our Services.
        </Typography>
        <Typography variant="body1" paragraph>
          - Authorities if required by law or to protect our legal rights.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement robust security measures to protect your information.
          However, no system is completely secure, and we cannot guarantee the
          absolute security of your data.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to:
        </Typography>
        <Typography variant="body1" paragraph>
          - Access, correct, or delete your personal information.
        </Typography>
        <Typography variant="body1" paragraph>
          - Opt out of marketing communications.
        </Typography>
        <Typography variant="body1" paragraph>
          - Restrict or object to certain data processing activities.
        </Typography>
        <Typography variant="body1" paragraph>
          To exercise these rights, contact us at
          [support@pioneerinvesting.com].
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          6. Cookies and Tracking
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar technologies to enhance your experience.
          You can manage your cookie preferences through your browser settings.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          7. Third-Party Links
        </Typography>
        <Typography variant="body1" paragraph>
          Our platform may contain links to third-party websites. We are not
          responsible for the privacy practices of these websites.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          8. Children’s Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our Services are not intended for children under the age of 18. We do
          not knowingly collect personal information from children.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          9. Changes to This Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. Updates will be
          posted on our website, and your continued use of the Services
          constitutes acceptance of the revised policy.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          10. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy,
          contact us at [support@pioneerinvesting.com].
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
