import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Link,
  Box,
  IconButton,
  Drawer,
  RadioGroup,
  FormControlLabel,
  Radio,
  PaletteMode,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import PiInvLogo from "./PiInvLogo";
import { md3ThemeJson } from "../../Theme";
import "../styles/navbar.css";

interface NavbarProps {
  setMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
}

const Navbar: React.FC<NavbarProps> = ({ setMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<string>("system");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTheme = event.target.value;
    setSelectedTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    setMode(
      newTheme === "system"
        ? window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light"
        : (newTheme as PaletteMode),
    );
  };

  return (
    <Box className="navbar">
      <AppBar
        position="fixed"
        sx={{
          background: `${md3ThemeJson.schemes.dark.background}cc`,
          backdropFilter: "blur(6px)",
          color: "inherit",
          overflow: "hidden",
          zIndex: 1100,
          boxShadow: "none",
          height: "4rem",
        }}
      >
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 0 }}>
            <Link href="/">
              <PiInvLogo />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleMenu}
            >
              {menuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={menuOpen}
        onClose={toggleMenu}
        PaperProps={{
          sx: {
            background: `${md3ThemeJson.schemes.dark.background}cc`,
            backdropFilter: "blur(6px)",
            color: md3ThemeJson.schemes.dark.onSurface,
            width: "70%",
            height: "100%",
            top: "4rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
        >
          <Link
            underline="none"
            href="/"
            sx={{
              color: "inherit",
              padding: "1rem",
              textDecoration: "none",
              textAlign: "center",
              fontSize: "1.1rem",
            }}
          >
            Home
          </Link>
          <Link
            underline="none"
            href="https://pitrade.pioneerinvesting.com/"
            sx={{
              color: md3ThemeJson.schemes.dark.golden,
              padding: "1rem",
              textDecoration: "none",
              textAlign: "center",
              fontSize: "1.1rem",
            }}
          >
            PiTrade
          </Link>
          <Link
            underline="none"
            onClick={toggleSettings}
            sx={{
              color: "inherit",
              padding: "1rem",
              textDecoration: "none",
              textAlign: "center",
              fontSize: "1.1rem",
            }}
          >
            <Box>
              <SettingsIcon color="disabled" />
              <ArrowDropDownIcon />
            </Box>
          </Link>

          <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "transparent",
                padding: "0.5rem 1rem",
                textAlign: "center",
              }}
            >
              <RadioGroup
                aria-label="theme"
                name="theme"
                value={selectedTheme}
                onChange={handleThemeChange}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <FormControlLabel
                  value="system"
                  control={<Radio />}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1.1rem",
                      }}
                    >
                      <SettingsBrightnessIcon sx={{ marginRight: 1 }} />
                      System Default
                    </Box>
                  }
                />
                <FormControlLabel
                  value="light"
                  control={<Radio />}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1.1rem",
                      }}
                    >
                      <LightModeIcon sx={{ marginRight: 1 }} />
                      Light
                    </Box>
                  }
                />
                <FormControlLabel
                  value="dark"
                  control={<Radio />}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1.1rem",
                      }}
                    >
                      <DarkModeIcon sx={{ marginRight: 1 }} />
                      Dark
                    </Box>
                  }
                />
              </RadioGroup>
            </Box>
          </Collapse>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Navbar;
